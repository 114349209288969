<template>
  <div>
    <table-page dataName="list"
                ref="table"
                @on-success="fetchSuccess"
                :params="{equity_id:$route.query.id}"
                :request="getEquityRecord">
      <template slot="button">
        <FileExport :params="exportParams"
                    v-if="exportParams">导出数据</FileExport>
      </template>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left"
                   inline
                   class="demo-table-expand">
            <el-form-item label="权益类型">
              <span>{{ props.row.equity_type | typeName }}</span>
            </el-form-item>
            <template v-if="props.row.equity_type === 'COUPON'">
              <el-form-item label="代金券名称">
                <span>{{ props.row.prize_name }}</span>
              </el-form-item>
              <el-form-item label="批次号">
                <span>{{ props.row.prize_coupon_stock_id }}</span>
              </el-form-item>
              <el-form-item label="代金券id">
                <span>{{ props.row.prize_coupon_id }}</span>
              </el-form-item>
              <el-form-item label="核销状态">
                <span>{{ props.row.wx_status | couponStatus}}</span>
              </el-form-item>
            </template>
            <template v-else-if="props.row.equity_type === 'REFUEL'">
              <el-form-item label="兑换手机号">
                <span>{{ props.row.refuel.tel }}</span>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item label="卡密名称">
                <span>{{ props.row.prize_name }}</span>
              </el-form-item>
              <el-form-item label="兑换码">
                <span>{{ props.row.prize_card_key }}</span>
              </el-form-item>
            </template>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="user_name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="user_tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="user_plate_number"
                       show-overflow-tooltip
                       label="车牌号">
      </el-table-column>
      <el-table-column prop="user_id_code"
                       show-overflow-tooltip
                       label="身份证号">
      </el-table-column>
      <el-table-column prop="openid"
                       show-overflow-tooltip
                       label="OPENID">
      </el-table-column>

      <el-table-column prop="received_at"
                       show-overflow-tooltip
                       label="提交时间">
      </el-table-column>
    </table-page>
    <Detail ref='detail' />
  </div>

</template>

<script>
import Detail from './components/Detail'
import FileExport from '@/components/FileExport'
import TablePage from '@/components/TablePage'
import { getEquityRecord } from '@/api/car'
export default {
  name: 'CarEquityRecord',
  data () {
    return {
      exportParams: null,
      getEquityRecord,
      dialogVisible: false,
      searchParams: {},
      exportUrl: '',
      search: [{
        type: 'input',
        name: '姓名',
        placeholder: '单行输入',
        key: 'name',
        value: ''
      }, {
        type: 'input',
        name: '手机号码',
        placeholder: '单行输入',
        key: 'tel',
        value: ''
      }, {
        type: 'input',
        name: '身份证号码',
        placeholder: '单行输入',
        key: 'id_code',
        value: ''
      }, {
        type: 'input',
        name: 'openid',
        placeholder: '单行输入',
        key: 'openid',
        value: ''
      }, {
        type: 'select',
        key: 'threshold',
        name: '人群类型',
        list: [
          {
            value: 'ALL',
            label: '普通会员'
          },
          {
            value: 'CRM_MEMBER',
            label: '实名会员'
          }
        ]
      }]
    }
  },
  components: {
    TablePage,
    Detail,
    FileExport
  },
  filters: {
    statusName (type) {
      let name = ''
      switch (type) {
        case 'NORMAL':
          name = '领取成功'
          break
        case 'FAIL':
          name = '领取失败'
          break
        case 'PENDING':
          name = '未领取'
          break
        default:
          break
      }
      return name
    },
    typeName (type) {
      let name = ''
      switch (type) {
        case 'COUPON':
          name = '代金券'
          break
        case 'CARD_KEY':
          name = '卡密'
          break
        case 'REFUEL':
          name = '加油券'
          break
        default:
          break
      }
      return name
    },
    couponStatus (type) {
      let name = ''
      switch (type) {
        case 'SENDED':
          name = '未使用'
          break
        case 'EXPIRED':
          name = '过期'
          break
        case 'USED':
          name = '已核销'
          break
        default:
          break
      }
      return name
    }
  },
  methods: {
    onSearch (e) {
      this.searchParams = e
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    fetchSuccess (e) {
      this.exportParams = e.export
    },
    exportData (url) {
      window.open(url, '_blank')
    },
    toDetail (scope) {
      this.$refs.detail.showDetail(scope)
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
